import Vue from 'vue';
import VueRouter from 'vue-router';
import { checkAuthIfNeedly } from './utils';

Vue.use(VueRouter);

/* eslint-disable no-shadow */
const routes = [
  /*
  * Флоу авторизации через ЕСИА Бастион
  * */

  // Логин
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  // Callback авторизации через ЕСИА
  {
    path: '/authorized-callback',
    name: 'authorized-callback',
    component: () => import('../views/AuthorizedCallback.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  // Ошибка callback авторизации через ЕСИА
  {
    path: '/authorized-callback-error',
    name: 'authorized-callback-error',
    component: () => import('../views/AuthorizedCallbackError.vue'),
  },
  // Регистрация пользователя с Профи клуба bast.ru
  {
    path: '/registration-old-club/',
    name: 'registration-old-club-slash',
    component: () => import('../views/RegistrationOldProfiClub.vue'),
    props: (route) => ({
      userUuidOldProfiClub: route.query.userUuidOldProfiClub,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/registration-old-club',
    name: 'registration-old-club',
    component: () => import('../views/RegistrationOldProfiClub.vue'),
    props: (route) => ({
      userUuidOldProfiClub: route.query.userUuidOldProfiClub,
    }),
    meta: {
      requiresAuth: true,
    },
  },

  /*
  * Личный кабинет
  * */
  // Главная страница кабинета
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tests',
    name: 'tests',
    component: () => import('../views/Tests.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tests/:id',
    name: 'test',
    component: () => import('../views/Test.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Профи-Клуб
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/About.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // Бонусы
  {
    path: '/bonuses',
    name: 'bonuses',
    component: () => import('../views/Bonuses.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/promotions',
    name: 'promotions',
    component: () => import('../views/Promotions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/promotions/:id',
    name: 'promotion',
    component: () => import('../views/Promotion.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/assembly',
    name: 'assembly',
    component: () => import('../views/Assembly.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gifts',
    name: 'gifts',
    component: () => import('../views/Gifts.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gift',
    name: 'gift',
    component: () => import('../views/GiftPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/promocode',
    name: 'promocode',
    component: () => import('../views/Promocode.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Список привязанных устройств
  {
    path: '/devices',
    name: 'devices',
    component: () => import('../views/Devices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Привязать устройство
  {
    path: '/bind-device',
    name: 'bind-device',
    component: () => import('../views/BindDevice.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Привязать устройство (этот path закодирован в наклейках печать 1С)
  {
    path: '/add-device',
    name: 'add-device',
    component: () => import('../views/BindDevice.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Профиль
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Изменить номер телефона
  {
    path: '/change-phone-number',
    name: 'change-phone-number',
    component: () => import('../views/ChangePhoneNumber.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Изменить email
  {
    path: '/change-email',
    name: 'change-email',
    component: () => import('../views/ChangeEmail.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Присоединиться к семинару
  {
    path: '/join-to-seminar/:seminarId',
    name: 'join-to-seminar',
    component: () => import('../views/JoinToSeminar.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },

  /*
  * Технические роуты
  * */
  {
    path: '/',
    name: 'index',
    redirect: '/home',
  },
  // Псевдо 404 страница
  {
    path: '/404',
    name: '404',
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Проверка токена авторизации при переходе по ссылкам внутри сайта
router.beforeEach(checkAuthIfNeedly);

export default router;
/* eslint-enable no-shadow */
