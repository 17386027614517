import { dadataAPI } from './index';

export async function getCompaniesSuggestionByInn(query) {
  const response = await dadataAPI.post('findById/party', { query });
  return (response.data && response.data.suggestions) ? response.data.suggestions : null;
}

export async function getCompaniesSuggestionByEntityName(query) {
  const response = await dadataAPI.post('suggest/party', { query });
  return (response.data && response.data.suggestions) ? response.data.suggestions : null;
}
// удаляет планировочные структуры
function removeNonCity(suggestions) {
  return suggestions.filter((suggestion) => suggestion.data.fias_level !== '65');
}
export async function getCitiesSuggestion(query) {
  const response = await dadataAPI.post('suggest/address', {
    query,
    count: 20,
    from_bound: { value: 'city' },
    to_bound: { value: 'settlement' },
  });
  return (response.data && response.data.suggestions)
    ? removeNonCity(response.data.suggestions) : null;
}
