import jsonwebtoken from 'jsonwebtoken';
import store from '../store';
import router from '../router';
import { config } from '../config/index';

/* eslint-disable no-param-reassign */
// Интерцептор: прикрепляет заголовок авторизации
export const interceptorRequestAddJwt = (configRequest) => {
  const jwt = store.getters['auth/jwt'];
  if (jwt) configRequest.headers.Authorization = `Bearer ${jwt}`;
  // Иначе отправить без заголовка Authorization
  return configRequest;
};
export const interceptorRequestRejected = (error) => Promise.reject(error);
/* eslint-enable no-param-reassign */

// Интерцептор: проверка на 401 HTTP ответ
export const interceptorResponseFulfilled = (response) => response;
export const interceptorResponseRejected = async (responseError) => {
  const status = responseError.response ? responseError.response.status : null;

  if (status !== 401) {
    return Promise.reject(responseError);
  }

  // Пробуем получить новую пару по RefreshToken.
  const { error } = await store.dispatch('auth/useRefreshToken');
  if (error !== undefined) {
    router.push({
      name: 'authorized-callback-error',
      params: { error },
    });
    return Promise.reject(responseError);
  }
  this.request(responseError.config);
  return Promise.resolve();
};

// Интерцептор: проверка даты экспирации токена и при необходимости обновление токена
export const interceptorRequestExpirationDate = async (configRequest) => {
  const jwt = store.getters['auth/jwt'];
  const decode = jsonwebtoken.decode(jwt, []);

  if (jwt !== undefined || null) {
    if (decode.exp > Math.round(Date.now() / 1000) + config.jwtExpDeltaSeconds) {
      return configRequest;
    }

    const { error } = await store.dispatch('auth/useRefreshToken');
    if (error !== undefined) {
      router.push({
        name: 'authorized-callback-error',
        params: { error },
      });
      return Promise.reject(error);
    }

    return configRequest;
  }

  return Promise.reject(Error('Отсутствует JWT'));
};
