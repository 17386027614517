import axios from 'axios';
import { config } from '../config';
import {
  interceptorRequestAddJwt,
  interceptorRequestRejected,
  interceptorResponseRejected,
  interceptorResponseFulfilled,
  interceptorRequestExpirationDate,
} from './interceptors';

/* Auth Service API */
export const axiosAuthServiceApi = axios.create({
  baseURL: config.apiAuthServiceUrl,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

/* Auth Service API with credentials */
export const axiosAuthServiceApiWithCredentials = axios.create({
  baseURL: config.apiAuthServiceUrl,
  // Вообще withCredentials это история о куках.
  // Пока их не юзаем, но всякое может быть.
  //
  // UPD: `withCredentials: true` не будет работать
  // так как CORS на бекенде не настроен работать с куками.
  // withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
axiosAuthServiceApiWithCredentials.interceptors.request.use(
  interceptorRequestAddJwt,
  interceptorRequestRejected,
);
axiosAuthServiceApiWithCredentials.interceptors.request.use(interceptorRequestExpirationDate);
axiosAuthServiceApiWithCredentials.interceptors.response.use(
  interceptorResponseFulfilled,
  interceptorResponseRejected,
);

/* Профи-клуб API with credentials */
export const axiosProfiClubApiWithCredentials = axios.create({
  baseURL: config.apiProfiClubUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
axiosProfiClubApiWithCredentials.interceptors.request.use(
  interceptorRequestAddJwt,
  interceptorRequestRejected,
);
axiosProfiClubApiWithCredentials.interceptors.request.use(interceptorRequestExpirationDate);
axiosProfiClubApiWithCredentials.interceptors.response.use(
  interceptorResponseFulfilled,
  interceptorResponseRejected,
);

/* API DaData */
export const dadataAPI = axios.create({
  baseURL: config.dadataSuggestionsUrl,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${config.dadataApiKey}`,
  },
});
