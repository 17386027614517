<template>
  <footer class="footer-component pb-0">
    <b-container>
      <b-row class="align-items-center">
        <b-col class="footer-links" cols="5">
          <b-row class="footer__links__row">
            <b-col>
              <p>
                <b-link
                    href="https://bast.ru"
                    target="_blank"
                >
                  Сайт компании
                </b-link>
                <b-link
                    href="https://skat-ups.ru"
                    target="_blank"
                >
                  Интернет-магазин
                </b-link>
              </p>
            </b-col>
            <b-col>
              <p>
                <a href="tel:+78002005830" class="mr-3">8-800-200-58-30</a>
                <a href="mailto:club@bast.ru">club@bast.ru</a>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <div class="d-flex justify-content-end align-items-center">
            <a href="https://vk.com/po_bastion"><Vk class="mr-2"/></a>
            <a href="https://www.youtube.com/c/wwwbastru/featured"><YouTube/></a>
          </div>
          <p class="text-right">
            <a
               href="https://bast.ru/company/politika-konfidenczialnosti"
               target="_blank">
              Политика конфиденциальности
            </a>
          </p>
          <p class="text-right">ЗАО Бастион © 1991-{{ year }}</p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import moment from 'moment';
import Vk from '../assets/vk.svg';
import YouTube from '../assets/youtube.svg';

export default {
  name: 'Footer',
  data: () => ({
    year: null,
  }),
  components: {
    Vk,
    YouTube,
  },
  async mounted() {
    this.year = moment().format('YYYY');
  },
};
</script>

<style lang="scss">
@import '../assets/_variables.scss';
.footer-component {
  padding: 10px;
  background-color: $bastion-blue-color;
  color: #fff;
  text-align: start;
  margin-top: auto;

  p {
    margin-bottom: 0.3em;
    display: flex;
    flex-direction: column;
  }
  a{
    color: #fff;
  }
  a:hover{
    color: #fff;
  }
}
@media (max-width: 992px) {
  a,p{
    font-size: 12px;
  }
  .footer__links__row {
    flex-direction: column;
  }
}
</style>
