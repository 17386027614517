/* eslint-disable no-shadow */
import { getBonuses } from '../../api/profi_club/bonuses';

function initialState() {
  return {
    // Состояние загрузки
    total: null,
    isLoading: false,
    isServerError: false,
  };
}

const getters = {
  bonuses(state) {
    return state.total;
  },
  isLoading(state) {
    return state.isLoading;
  },
};

const actions = {
  /* Получение новой пары по RefreshToken */
  async fetchBonuses({ commit }) {
    commit('setLoading');
    const response = await getBonuses();
    commit('setLoaded');
    commit('setBonuses', response);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  setBonuses(state, data) {
    state.total = data;
  },
  setLoading(state) {
    state.isLoading = true;
  },
  setIsServerError(state) {
    state.isServerError = false;
  },
  setLoaded(state) {
    state.isLoading = false;
  },
};
/* eslint-enable no-param-reassign */

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
/* eslint-enable no-shadow */
