import { axiosAuthServiceApiWithCredentials } from '../index';

/* Получение персональной информации */
export async function getPersonalData() {
  const urlSegment = 'personal-data';
  const response = await axiosAuthServiceApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}
/* /Получение персональной информации */

/* Изменить персональную информации */
export async function changePersonalData(firstName, lastName, isProfessionalUser, country, city) {
  const payload = {
    firstName, lastName, isProfessionalUser, country, city,
  };
  const urlSegment = 'personal-data';
  const response = await axiosAuthServiceApiWithCredentials.put(urlSegment, payload);
  return response.data;
}
/* /Изменить персональную информации */
