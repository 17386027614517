/* eslint-disable no-shadow */
import { changePersonalData, getPersonalData } from '../../api/esia/personal_data';

function initialState() {
  return {
    // Состояние загрузки
    isLoading: false,

    // Состояние ошибки
    isServerError: false,
    city: null,
    country: null,
    email: null,
    emailConfirmed: false,
    firstName: null,
    fullProfileData: false,
    isProfessionalUser: false,
    lastName: null,
    phoneNumber: null,
  };
}

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  isServerError(state) {
    return state.isServerError;
  },

  firstName(state) {
    return state.firstName;
  },
  country(state) {
    return state.country;
  },
  city(state) {
    return state.city;
  },
  lastName(state) {
    return state.lastName;
  },
  email(state) {
    return state.email;
  },
  emailConfirmed(state) {
    return state.emailConfirmed;
  },
  fullProfileData(state) {
    return state.fullProfileData;
  },
  isProfessionalUser(state) {
    return state.isProfessionalUser;
  },
  phoneNumber(state) {
    return state.phoneNumber;
  },
};

const actions = {
  /* Получение новой пары по RefreshToken */
  async fetchPersonalData({ commit }) {
    commit('setLoading');
    const response = await getPersonalData();
    commit('setLoaded');
    commit('setPersonalData', response);
  },
  async updatePersonalData({ getters }) {
    await changePersonalData(getters.firstName, getters.lastName,
      getters.isProfessionalUser, getters.country, getters.city);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  setPersonalData(state, data) {
    state.city = data.city;
    state.country = data.country;
    state.email = data.email;
    state.emailConfirmed = data.emailConfirmed;
    state.firstName = data.firstName;
    state.fullProfileData = data.fullProfileData;
    state.isProfessionalUser = data.isProfessionalUser;
    state.lastName = data.lastName;
    state.phoneNumber = data.phoneNumber;
  },
  setLoading(state) {
    state.isLoading = true;
    state.isServerError = false;
  },
  setLoaded(state) {
    state.isLoading = false;
  },
};
/* eslint-enable no-param-reassign */

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
/* eslint-enable no-shadow */
