import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueTheMask from 'vue-the-mask';
import './filters';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './i18n';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueTheMask);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
