<template>
  <b-nav
    :vertical="vertical"
    class="nav"
  >
    <b-nav-item
      to="/devices"
      exact-active-class="active"
    >
      <b-icon
        v-if="!vertical"
        icon="cpu-fill"
        class="d-block mx-auto mb-1"
      />
      <b-icon
        v-else
        icon="cpu-fill"
      />
      Устройства
    </b-nav-item>
    <b-nav-item
      to="/bonuses"
      exact-active-class="active"
    >
      <b-icon
        v-if="!vertical"
        icon="gift-fill"
        class="d-block mx-auto mb-1"
      />
      <b-icon
        v-else
        icon="gift-fill"
      />
      Бонусы
    </b-nav-item>
    <b-nav-item
      class="flex-xl-column flex-row"
      to="/promotions"
      exact-active-class="active"
    >
      <MegafonIcon class="megafone d-xl-flex ml-1 mr-2 mx-xl-auto mb-1"/>
      <span>Акции</span>
    </b-nav-item>
    <b-nav-item
      to="/promocode"
      exact-active-class="active"
    >
      <b-icon
        v-if="!vertical"
        icon="lightning-fill"
        class="d-block mx-auto mb-1"
      />
      <b-icon
        v-else
        icon="lightning-fill"
      />
      Промокод
    </b-nav-item>
    <b-nav-item
      to="/tests"
      exact-active-class="active"
    >
      <b-icon
        v-if="!vertical"
        icon="card-checklist"
        class="d-block mx-auto mb-1"
      />
      <b-icon
        v-else
        icon="card-checklist"
      />
      Тесты
    </b-nav-item>
    <b-nav-item
      to="/profile"
      v-if="vertical"
      exact-active-class="active"
    >
      <b-icon
        icon="person-circle"
      />
      Аккаунт
    </b-nav-item>
  </b-nav>
</template>

<script>
import MegafonIcon from '../assets/megafon.svg';

export default {
  name: 'NavLinks',
  components: {
    MegafonIcon,
  },
  props: {
    vertical: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.nav {
  flex-wrap: nowrap;
}
@media (max-width: 1200px) {
  a {
    color: #fff;
    font-size: 1.5em;
  }
  .active {
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0;
  }
}
</style>
