<template>
    <div
        class="personal-data py-3 w-100"
    >
        <b-container class="d-flex personal-data__container p-0">

            <!-- Аватар, Телефон, Имя и Фамилия -->

              <!-- Аватар -->
          <router-link
            to="/profile"
            style="text-decoration: none;"
            class="d-flex account text-light align-items-center"
          >
            <div class="d-flex flex-row justify-content-between align-items-center flex-nowrap">
              <div>
                  <b-icon
                      variant="light"
                      class="mr-3"
                      icon="person-circle"
                      font-scale="2"
                  />
              </div>
              <!-- Телефон, Имя и Фамилия -->
              <div>
                <div v-if="phoneNumber">
                  {{phoneNumber}}
                </div>
                <div v-if="firstName || lastName">
                  <span v-if="firstName">
                    {{firstName}}
                  </span>
                  <span
                      v-if="lastName"
                  >
                    {{lastName}}
                  </span>
                </div>
              </div>
            </div>
          </router-link>
            <!-- Бонусы -->
          <router-link
              to="/bonuses"
              style="text-decoration: none;"
              class="text-light bonuses d-flex align-items-center"
          >
            <div class="text-right ml-3" style="min-width: 120px">
              <h5
                  v-if="this.bonuses"
                  class="mb-0 font-weight-bold"
              >
                {{ bonusesTotal }}
                <b-icon
                    icon="lightning-fill"
                    aria-hidden="true"
                    font-scale="1"
                    variant="warning"
                ></b-icon>
              </h5>
              <a>Всего амперчиков</a>
            </div>
          </router-link>
        </b-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PersonalData',

  data() {
    return {
      serverErrorMessage: null,
    };
  },

  methods: {
    ...mapActions('bonuses', {
      fetchBonuses: 'fetchBonuses',
    }),
    ...mapActions('personalData', {
      fetchPersonalData: 'fetchPersonalData',
    }),
    async loadBonuses() {
      try {
        await this.fetchBonuses();
      } catch (e) {
        this.serverErrorMessage = e;
      }
    },
  },
  computed: {
    ...mapGetters('bonuses', {
      bonuses: 'bonuses',
    }),
    bonusesTotal() {
      return this.bonuses.total;
    },
    ...mapGetters('personalData', {
      firstName: 'firstName',
      lastName: 'lastName',
      phoneNumber: 'phoneNumber',
    }),
  },
  async mounted() {
    if (!this.lastName) {
      await this.fetchPersonalData();
    }
    if (!this.bonuses) {
      await this.loadBonuses();
    }
  },
};
</script>

<style lang="scss">
@import '../assets/_variables.scss';
.personal-data {
  background-color: $bastion-blue-color;
  color: #fff;
}
.container{
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .container {
    justify-content: space-between !important;
  }
}
</style>
