export const config = {
  baseUrl: process.env.VUE_APP_BASE_URL,
  authServiceEndpoint: process.env.VUE_APP_AUTH_SERVICE_URL,
  dadataSuggestionsUrl: process.env.VUE_APP_DADATA_SUGGESTIONS_URL,
  dadataApiKey: process.env.VUE_APP_DADATA_API_KEY,
  jwtExpDeltaSeconds: Number(process.env.VUE_APP_JWT_EXP_DELTA_SECONDS),
  apiProfiClubUrl: process.env.VUE_APP_API_PROFI_CLUB_URL,
  apiAuthServiceUrl: process.env.VUE_APP_API_AUTH_SERVICE_URL,
  apiAuthServiceTitleIdent: process.env.VUE_APP_SERVICE_TITLE,
  publicKeyForJwt: process.env.VUE_APP_JWT_PUBLIC_KEY,
  allowDomainsQrScan: ['localhost', 'profi-club-dev.bast.ru', 'club.bast.ru'],
};
