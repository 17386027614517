import Vue from 'vue';
import Vuex from 'vuex';

import personalData from './modules/personalData';
import auth from './modules/auth';
import bonuses from './modules/bonuses';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    personalData,
    bonuses,
  },
});
