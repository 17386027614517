import { axiosProfiClubApiWithCredentials } from '../index';

/**
 * Получить список и количество бонусов
 *
 * @returns {Promise<any|null>}
 */
export async function getBonuses() {
  const urlSegment = 'bonuses';
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}

/**
 * Получить количество бонусов
 *
 * @returns {Promise<any|null>}
 */
export async function getBonuseBalance() {
  const urlSegment = 'bonuses/balance';
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}

/**
 * Запрос на перенос бонусов из Профи-Клуб bast.ru в текущий
 *
 * @param userUuidOldProfiClub {String} Идентификатор пользователя в Профи-Клуб bast.ru
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function postTransferFromOldClub(userUuidOldProfiClub) {
  const urlSegment = 'bonuses/transfer-from-old-club';
  const response = await axiosProfiClubApiWithCredentials.post(
    urlSegment,
    { userUuidOldProfiClub },
  );

  return response;
}
