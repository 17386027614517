<template>
  <b-button
      :class="this.classStyle"
      :size="size"
      :href="esiaHref"
  >
    {{ buttonText }}
    <slot/>
  </b-button>
</template>

<script>
import { config } from '../config';

export default {
  name: 'EsiaLoginButton',

  props: {
    size: {
      type: String,
      default: 'md',
    },
    forward: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: 'Вход',
    },
    classStyle: {
      type: String,
      default: 'btn',
    },
  },

  computed: {
    esiaHref() {
      const href = `${this.authServiceEndpoint}?service=${this.apiAuthServiceTitleIdent}`;
      if (!this.forward) {
        return href;
      }
      // Прокидываем путь для редиректа при успешной авторизации
      return `${href}&forward=${this.forward}`;
    },
  },

  data() {
    return {
      authServiceEndpoint: config.authServiceEndpoint,
      apiAuthServiceTitleIdent: config.apiAuthServiceTitleIdent,
    };
  },

  async mounted() {
    if (this.forward && this.forward.indexOf('/join-to-seminar/') > -1) {
      this.buttonText = 'Зарегистрироваться';
    }
  },
};
</script>

<style lang="scss">
</style>
