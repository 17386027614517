<template>
  <div id="app">
    <Header class="header"/>
    <router-view class="router"/>
    <Footer class="footer"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import jsonwebtoken from 'jsonwebtoken';
import { config } from './config';
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';

export default {
  name: 'App',

  components: {
    Header,
    Footer,
  },

  data() {
    return {
      error: null,
    };
  },

  computed: {
    ...mapGetters('auth', {
      jwt: 'jwt',
    }),
  },

  methods: {
    async checkUserEmail() {
      try {
        // Берём данные пользователя из JWT
        const profile = await jsonwebtoken.verify(this.jwt, config.publicKeyForJwt);

        // Проверка email пользователя
        if (Object.prototype.hasOwnProperty.call(profile, 'email') && profile.email !== null) {
          return;
        }

        const vNodesMsg = this.$createElement(
          'p',
          { class: ['mb-0'] },
          [
            'Вы не указали электронную почту. Перейдите в ',
            this.$createElement('b-link', { props: { to: '/profile' } }, 'Личный кабинет'),
            ', чтоб указать email.',
          ],
        );

        // Показываем уведомление
        this.$bvToast.toast(
          vNodesMsg,
          {
            title: 'Привяжите email',
            toaster: 'b-toaster-bottom-left',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          },
        );
      } catch (err) {
        this.error = err;
      }
    },
  },

  async mounted() {
    document.getElementById('loading-block').style.display = 'none';

    // TODO: потом сделать без setTimeout
    setTimeout(this.checkUserEmail, 1000);
  },
};
</script>

<style lang="scss">
@import 'assets/bootstrap-custom.scss';

#app {
  overflow: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header{
    flex-grow: 0;
  }
  .router{
    flex-grow: 1;
  }
  .footer{
    flex-grow: 0;
  }
  .sidebar-menu-button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
  }
}
</style>
