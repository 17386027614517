/* eslint-disable no-shadow */
import jsonwebtoken from 'jsonwebtoken';
import { axiosAuthServiceApi } from '../../api';
import { config } from '../../config';

function initialState() {
  return {
    // Состояние загрузки
    isLoading: false,

    // Состояние ошибки
    isServerError: false,

    // JSON Web Token
    jwt: null,
  };
}

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  isServerError(state) {
    return state.isServerError;
  },

  jwt(state) {
    return state.jwt;
  },

  refreshToken: () => () => localStorage.getItem('refreshToken') || null,
};

const actions = {
  /* Получение новой пары по RefreshToken */
  async useRefreshToken({ commit, getters }) {
    commit('setLoading');
    const refreshToken = getters.refreshToken();

    // Проверка наличия токена обновления
    if (refreshToken === null) return { error: 'Ошибка токена обновления' };

    const urlSegment = `auth/refresh/${getters.refreshToken()}`;
    let result = {};
    let profile = {};

    // Перевыпуск токенов
    try {
      result = await axiosAuthServiceApi.get(urlSegment);
    } catch (err) {
      commit('setLoaded');
      commit('deleteTokens');
      return err;
    }

    // Проверка подписи jwt
    try {
      profile = jsonwebtoken.verify(result.data.jwt, config.publicKeyForJwt);
    } catch (e) {
      return { error: 'Не валидный токен доступа' };
    }

    // Проверка scope
    if (!profile.scope.includes(config.apiAuthServiceTitleIdent)) {
      return { error: 'Область доступа токена не для текущего сервиса' };
    }

    commit('setLoaded');
    commit('setTokens', {
      jwt: result.data.jwt,
      refreshToken: result.data.refreshToken,
    });
    return result;
  },

  /* Выход */
  signOut({ commit }) {
    commit('setLoading');
    localStorage.removeItem('refreshToken');
    document.location.href = '/login';
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  setLoading(state) {
    state.isLoading = true;
    state.isServerError = false;
  },

  setLoaded(state) {
    state.isLoading = false;
  },

  /* Установка токенов авторизации */
  setTokens(state, tokens) {
    // Сохранить JWT токен в память
    state.jwt = tokens.jwt;
    // Сохранить RefreshToken в localStorage
    localStorage.setItem('refreshToken', tokens.refreshToken);
  },

  /* Удаление токенов авторизации */
  deleteTokens(state) {
    localStorage.removeItem('refreshToken');
    state.jwt = null;
    state.refreshToken = null;
  },
};
/* eslint-enable no-param-reassign */

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
/* eslint-enable no-shadow */
