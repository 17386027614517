import store from '../store';

// Метод проверки юзера на авторизацию,
// который делает редирект на страницу авторизации (если не авторизован)
// или на страницу которую переходил юзер (если авторизован).
// Замечание: метод так же проверяет наличие RefreshToken
// и пробует его использовать (если не авторизован).
// Использовать для роутов которые требуют обязательно быть авторизованным.
const onlyAuthWay = async (to, from, next) => {
  const queryParams = from.query;
  // Крепим путь куда пытались попасть
  queryParams.forward = to.fullPath;
  if (!store.getters['auth/jwt']) {
    // JWT нет либо протух. Пробуем получть новую пару по RefreshToken.
    // Но сначала проверим наличие Refresh Token.
    if (store.getters['auth/refreshToken']() === null) {
      // Нет Refresh Token.
      // Перенапраляем на страницу входа
      next({ name: 'login', query: queryParams });
    } else {
      // Есть Refresh Token.
      const { error } = await store.dispatch('auth/useRefreshToken');
      next(error ? { name: 'authorized-callback-error', params: { error } } : undefined);
    }
  } else {
    // JWT прошел проверку.
    // Переходим туда куда изначально хотели.
    next();
  }
};

// Метод проверки юзера на ОТСУТСТВИЕ авторизации,
// иными словами если авторизован то редирект на домашнюю страницу,
// не авторизован - редирект на страницу на которую переходил юзер.
const onlyNoAuthWay = (to, from, next) => {
  const queryParams = to.query;

  if (store.getters['auth/jwt'] || store.getters['auth/refreshToken']()) {
    // Учитывая есть ли forward (кидаем на него)
    if (Object.prototype.hasOwnProperty.call(queryParams, 'forward')) {
      next(queryParams.forward);
      return;
    }

    next({ name: 'home' });
  } else {
    // Переходим туда куда изначально хотели.
    next();
  }
};

// Обертка, которая проверяет роут (нужна авторизация или обязательно не нужна)
// и вызывает функцию onlyAuthWay или onlyNoAuthWay.
export async function checkAuthIfNeedly(to, from, next) {
  const requiresAuth = Object.prototype.hasOwnProperty.call(to, 'meta')
        && Object.prototype.hasOwnProperty.call(to.meta, 'requiresAuth');
    // Поле meta.requiresAuth может работать в одном из трех режимов:
    // * указано и равно true - этот путь требует быть АВТОРИЗОВАННЫМ;
    // * указано и  равно false - этот путь требует быть НЕ АВТОРИЗОВАННЫМ;
    // * не указано - этот путь работает со всеми пользователями.
    // Проверка: нужно ли проверять авторизацию
  if (requiresAuth) {
    // Попали в эту ветку значит нужно проверять авторизацию.
    if (to.meta.requiresAuth) {
      // Этот путь требует быть АВТОРИЗОВАННЫМ - проверяем залогинен ли
      // пользователь и если нет, перенаправляем на страницу входа.
      await onlyAuthWay(to, from, next);
    } else {
      // Этот путь требует быть НЕ АВТОРИЗОВАННЫМ - проверяем залогинен ли
      // пользователь и если да, перенаправляем на главную.
      // Условием авторизации является наличие jwt или refreshToken.
      onlyNoAuthWay(to, from, next);
    }
  } else {
    // Попали в эту ветку значит НЕ нужно проверять авторизацию,
    // этот путь работает со всеми пользователями.
    // Переходим туда куда изначально направлялись.
    next();
  }
}
