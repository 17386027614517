<template>
  <div>
    <header class="header">
      <b-container class="header__container">
        <b-row class="justify-content-between align-items-center header__row">
          <b-col
              cols="12"
              sm="12"
              lg="12"
              xl="7"
              v-if="jwt"
          >
            <b-navbar
                variant="faded"
                type="dark"
                class="px-0 justify-content-between"
            >
              <b-navbar-brand
                  to="/home"
                  class="logo"
              >
                <BastionLogo />
              </b-navbar-brand>
              <template
              >
                <!-- Десктопное меню -->
                <b-navbar-nav class="d-none d-xl-flex">
                  <NavLinks/>
                </b-navbar-nav>

                <!-- Мобильное меню -->
                <div class="d-xl-none">
                    <b-icon
                      v-b-toggle.sidebar
                      icon="list"
                      variant="light"
                      scale="2"
                  />
                  <b-sidebar
                      width="100%"
                      id="sidebar"
                      shadow
                      text-variant="light"
                      bg-variant="primary"
                      header-class="header__button"
                  >
                    <div>
                      <NavLinks :vertical="true"/>
                    </div>
                  </b-sidebar>
                  </div>
              </template>
                <EsiaLoginButton
                    v-if="!jwt"
                    :forward="forward"
                >
                  <span class="text-light mr-3"></span>
                  <b-icon
                      icon="box-arrow-right"
                      variant="light"
                      scale="2"
                  />
                </EsiaLoginButton>
            </b-navbar>
          </b-col>
          <b-col
              cols="12"
              v-if="!jwt"
          >
            <b-navbar
                variant="faded"
                type="dark"
                class="px-0 justify-content-between"
            >
              <b-navbar-brand
                  to="/home"
                  class="logo"
              >
                <BastionLogo />
              </b-navbar-brand>
              <EsiaLoginButton
                  class-style="btn"
                  style="background-color: transparent; border: none;"
                  v-if="!jwt"
                  :forward="forward"
              >
                <span class="text-light mr-3"></span>
                <b-icon
                    icon="box-arrow-right"
                    variant="light"
                    scale="2"
                />
              </EsiaLoginButton>
            </b-navbar>
          </b-col>
          <b-col
              v-if="jwt"
              cols="12"
              sm="12"
              lg="12"
              xl="5"
              class="d-flex align-items-center"
          >
            <PersonalData v-if="jwt"/>
          </b-col>
        </b-row>
      </b-container>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavLinks from './NavLinks.vue';
import PersonalData from './PersonalData.vue';
import BastionLogo from '../assets/logo.svg';
import EsiaLoginButton from './EsiaLoginButton.vue';

export default {
  name: 'Header',

  components: {
    BastionLogo,
    PersonalData,
    NavLinks,
    EsiaLoginButton,
  },
  methods: {

  },
  computed: {
    ...mapGetters({
      jwt: 'auth/jwt',
    }),

    forward() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'forward')) {
        // Прокидываем путь для редиректа при успешной авторизации
        return this.$route.query.forward;
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@import '../assets/_variables.scss';
.header__button{
  button{
    margin: 0 !important;
    svg{
      width: 31px;
      height: 31px;
    }
  }
}
.header {
  z-index: 100;
  background-color: $bastion-blue-color;
  position: sticky;
  top: 0;

  @media (min-width: 1200px) {
    position: static;
  }

  &__container {
    overflow: visible!important;
  }
}
.menu__wrapper{
  position: fixed;
  display: none;
  flex-direction: column;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $bastion-blue-color;
  a:hover{
    color: #fff;
  }
}
.logo {
  svg {
    width: 200px;
  }
}
@media (max-width: 1200px) {
  .header__row {
    flex-direction: column;
  }
}
</style>
