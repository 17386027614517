import { getCitiesSuggestion } from '../api/dadata';
import { CATEGORY_TITLES } from '../constants';

export function getCategoryTitleById(uuid) {
  return CATEGORY_TITLES[uuid];
}
export function squeezeText(text, charAmount) {
  if (text) {
    if (String(text.length) > charAmount) {
      return `${String(text).slice(0, charAmount)}...`;
    }
    return text;
  }
  return null;
}
export function getDate(date) {
  const newDate = new Date(date * 1000);
  return `${String(newDate.getDate()).padStart(2, '0')}`
    + `.${String(newDate.getMonth() + 1).padStart(2, '0')}`
    + `.${String(newDate.getFullYear())}`;
}

export async function getListCity(value) {
  let listCity = [];
  if (!value || value === '' || value.length < 4) return listCity;
  try {
    listCity = await getCitiesSuggestion(value);
  } catch (e) {
    console.log(e);
  }
  return listCity;
}

/**
 * Форматирование серийного номера
 * @param sn :string
 * @returns string
 */
export function formatSerialNumber(sn) {
  return sn.split('').reduce((snPart, currentSymbol, index) => {
    if (index % 4 === 0) return `${snPart} ${currentSymbol}`;
    return snPart + currentSymbol;
  });
}
